<template>
  <div class="whoami">
    <div v-if="isLoaded">
      <b-dropdown>
        <template slot="button-content">
          {{ name }}
        </template>
        <b-dropdown-item @click="logout">{{
          $t("components.whoami.logOut")
        }}</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationUser",
  props: {
    user: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    name: function() {
      if (this.isLoaded) {
        return this.user.firstname + " " + this.user.lastname;
      }
      return "";
    },
    isLoaded: function() {
      return this.user !== undefined && this.user !== null;
    }
  },
  methods: {
    logout() {
      this.$eventBus.$emit("logout-action");
    }
  }
};
</script>
